/**
 * @file 前台 subRoutes
 */

import { flatten } from '@seiue/util'
import React from 'react'

import { $t } from 'packages/locale'

import { getRouteSlots } from '@/plugins/slot'

/**
 * Get user subRoutes
 *
 * @returns subRoutes
 */
export const getUserSubRoutes = () => [
  {
    path: 'home',
    name: 'Home',
    getTitle: () => $t('主页'),
    component: React.lazy(() =>
      import('../pages/user/Home').then(m => ({
        default: m.Home,
      })),
    ),
  },
  {
    path: 'attendances',
    name: 'Attendances',
    getTitle: () => $t('考勤'),
    component: React.lazy(() =>
      import('../pages/user/attendances/Member').then(m => ({
        default: m.Member,
      })),
    ),
  },
  {
    path: 'admin-attendances',
    name: 'AdminAttendances',
    getTitle: () => $t('考勤'),
    component: React.lazy(() =>
      import('../pages/user/attendances/Admin').then(m => ({
        default: m.Entry,
      })),
    ),
    subRoutes: [
      {
        path: 'day',
        name: 'Day',
        getTitle: () => $t('每日考勤'),
        component: React.lazy(() =>
          import('../pages/user/attendances/Admin/Day').then(m => ({
            default: m.Day,
          })),
        ),
      },
      {
        path: 'stat',
        getTitle: () => $t('考勤统计'),
        component: React.lazy(() =>
          import('../pages/user/attendances/Admin/Logs').then(m => ({
            default: m.Logs,
          })),
        ),
      },
    ],
  },
  {
    path: 'tasks',
    name: 'Tasks',
    getTitle: () => $t('任务'),
    component: React.lazy(() =>
      import('../pages/user/Tasks').then(m => ({
        default: m.Tasks,
      })),
    ),
  },
  {
    path: 'exams',
    name: 'ExamList',
    getTitle: () => $t('考试'),
    component: React.lazy(() =>
      import('../pages/user/MentorExamList').then(m => ({
        default: m.MentorExamList,
      })),
    ),
  },
  ...flatten(getRouteSlots('profileCustomizedUserSubRoutes')),
  {
    path: 'schedule',
    name: 'Schedule',
    getTitle: () => $t('日程'),
    component: React.lazy(() =>
      import('../pages/user/Schedule').then(m => ({
        default: m.SchedulePage,
      })),
    ),
  },
  {
    path: 'members',
    name: 'Members',
    getTitle: () => $t('成员'),
    component: React.lazy(() =>
      import('../pages/user/Members/Card').then(m => ({
        default: m.Members,
      })),
    ),
  },
  {
    path: 'admin-members',
    name: 'AdminMembers',
    getTitle: () => $t('成员'),
    component: React.lazy(() =>
      import('../pages/user/Members').then(m => ({
        default: m.Members,
      })),
    ),
    subRoutes: [
      {
        path: 'card',
        getTitle: () => $t('卡片视图'),
        component: React.lazy(() =>
          import('../pages/user/Members/MembersCard').then(m => ({
            default: m.MembersCard,
          })),
        ),
      },
      {
        path: 'table',
        getTitle: () => $t('表格视图'),
        component: React.lazy(() =>
          import('../pages/user/Members/Table').then(m => ({
            default: m.Members,
          })),
        ),
      },
    ],
  },
  {
    path: 'discussions',
    name: 'Discussions',
    getTitle: () => $t('讨论'),
    component: React.lazy(() =>
      import('../pages/user/Discussion').then(m => ({
        default: m.Discussion,
      })),
    ),
  },
]
