import { TagStatus } from '@seiue/ui'
import { createEnumHelper } from '@seiue/util'
import { lazy } from 'react'

import { getExamSubjectScoringStatusType } from 'packages/feature-utils/online-scoring/utils/data'
import { ModuleEnum, PluginEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { ExamScoringStatusEnum } from 'packages/sdks-next/vnas'

import { SubjectAnalysisTabKeyEnum } from '@/features/exams/pages/types'
import { DataSlotFns } from '@/plugins/slot/data-slots'

/**
 * 考试管理-考试列表--下拉「阅卷」
 *
 * @param param0 - props
 * @param param0.examId - 考试 id
 * @param param0.history - return of useHistory
 * @returns 下拉菜单
 */
export const examScoreDropdownItems: DataSlotFns['examScoreDropdownItems'] = ({
  examId,
  history,
}) => [
  {
    // 如果开了阅卷，默认展示在最上方
    index: 0,
    label: $t('阅卷'),
    callback: () => {
      history.push('AdminExamScorings', {
        examId,
      })
    },
  },
]

/**
 * 考试前台 sub routes slot
 */
export const examFrontendSubRoutes: RouteConfig[] = [
  {
    path: 'scoring-list',
    getTitle: () => $t('阅卷列表'),
    requireModules: [ModuleEnum.Exam, PluginEnum.OnlineScoring],
    component: lazy(() =>
      import('./pages/teacher/ExamScorings').then(m => ({
        default: m.ParticipatedScorings,
      })),
    ),
  },
]

/**
 * 考试 - 科目分析详情左侧边栏 tab 配置
 *
 * @param param0 - props
 * @param param0.isImportScoreMethod - 是否是外部导入试题
 * @param param0.hasScore - 是否有分数
 * @param param0.hasMarkTypical - 是否有优秀答卷
 * @param param0.hasMarkProblem - 是否有问题答卷
 * @returns tab 项
 */
export const examSubjectAnalysisSidebarTabItems: DataSlotFns['examSubjectAnalysisSidebarTabItems'] =
  ({ isImportScoreMethod, hasScore, hasMarkTypical, hasMarkProblem }) => {
    if (!hasScore) return []
    return [
      {
        label: '',
        key: 'teaching-divider',
        isDivider: true,
      },
      {
        label: $t('题目分析'),
        key: 'question-analysis',
        isSub: true,
      },
      {
        label: $t('题目概况'),
        key: SubjectAnalysisTabKeyEnum.QuestionOverview,
      },
      {
        label: $t('题目得分情况'),
        key: SubjectAnalysisTabKeyEnum.QuestionCondition,
      },
      // 如果是外部导入试题和分数的阅卷，不展示 「题目作答分布」界面
      ...(isImportScoreMethod
        ? []
        : [
            {
              label: $t('题目作答分布'),
              key: SubjectAnalysisTabKeyEnum.QuestionDistribution,
            },
          ]),
      ...(hasMarkTypical || hasMarkProblem
        ? [
            {
              label: '',
              key: 'typical-divider',
              isDivider: true,
            },
            {
              label: $t('典型卷'),
              key: 'student-block-marks',
              isSub: true,
            },
            ...(hasMarkTypical
              ? [
                  {
                    label: $t('优秀答卷'),
                    key: SubjectAnalysisTabKeyEnum.StudentBlockMarksTypical,
                  },
                ]
              : []),
            ...(hasMarkProblem
              ? [
                  {
                    label: $t('典型错误'),
                    key: SubjectAnalysisTabKeyEnum.StudentBlockMarksProblem,
                  },
                ]
              : []),
          ]
        : []),
      {
        label: '',
        key: 'question-divider',
        isDivider: true,
      },
      {
        label: $t('知识点分析'),
        key: 'knowledge-analysis',
        isSub: true,
      },
      {
        label: $t('知识点得分率'),
        key: SubjectAnalysisTabKeyEnum.KnowledgeScoreRate,
      },
      {
        label: '',
        key: 'knowledge-divider',
        isDivider: true,
      },
      {
        label: $t('学生成绩'),
        key: 'student-score',
        isSub: true,
      },
      {
        label: $t('学生小分表'),
        key: SubjectAnalysisTabKeyEnum.StudentScoreTable,
      },
    ]
  }

const getExamScoringStatusLabel = createEnumHelper(() => ({
  [ExamScoringStatusEnum.None]: '-',
  [ExamScoringStatusEnum.Pending]: $t('待阅卷'),
  [ExamScoringStatusEnum.Preparing]: $t('待阅卷'),
  [ExamScoringStatusEnum.Configured]: $t('待阅卷'),
  [ExamScoringStatusEnum.Recognising]: $t('待阅卷'),
  [ExamScoringStatusEnum.Recognised]: $t('待阅卷'),
  [ExamScoringStatusEnum.InProgress]: $t('阅卷中'),
  [ExamScoringStatusEnum.Completed]: $t('已结束'),
})).get

const getExamScoringStatusTag = createEnumHelper(() => ({
  [ExamScoringStatusEnum.None]: 'invalid' as const,
  [ExamScoringStatusEnum.Pending]: 'warning' as const,
  [ExamScoringStatusEnum.Preparing]: 'warning' as const,
  [ExamScoringStatusEnum.Configured]: 'warning' as const,
  [ExamScoringStatusEnum.Recognising]: 'warning' as const,
  [ExamScoringStatusEnum.Recognised]: 'warning' as const,
  [ExamScoringStatusEnum.InProgress]: 'ongoing' as const,
  [ExamScoringStatusEnum.Completed]: 'success' as const,
})).get

/**
 * 教师个人档案和课程班首页的 「考试」 界面的列表 - 列定义
 *
 * @returns 列
 */
export const teacherExamListColumns: DataSlotFns['teacherExamListColumns'] =
  () => {
    return [
      {
        title: $t('阅卷状态'),
        key: 'examScoringStatus',
        renderCell: row =>
          row.examScoring?.status &&
          // none 为待出卷，始终返回 '-'
          row.examScoring?.status !== ExamScoringStatusEnum.None ? (
            <TagStatus
              text={getExamScoringStatusLabel(row.examScoring.status)}
              status={getExamScoringStatusTag(row.examScoring.status)}
            />
          ) : (
            '-'
          ),
      },
    ]
  }

/**
 * 行政班详情 - 成绩 - 考试成绩列表列定义
 *
 * @returns 列
 */
export const adminClassExamGradeListColumns: DataSlotFns['adminClassExamGradeListColumns'] =
  () => {
    return [
      {
        key: 'scoringStatus',
        title: $t('阅卷状态'),
        renderCell: row =>
          row.scoringStatusGeneralLabel &&
          row.scoringStatusGeneralLabel !== '-' ? (
            <TagStatus
              status={getExamSubjectScoringStatusType({
                scoringStatusGeneralLabel: row.scoringStatusGeneralLabel,
              })}
              text={row.scoringStatusGeneralLabel}
            />
          ) : (
            '-'
          ),
      },
    ]
  }
