/**
 * @file ClassIn 路由定义
 */

import React from 'react'

import { PluginEnum } from 'packages/feature-utils/plugins'
import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { ModalRouteNode, RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

import { RouteMenus } from '@/router'

export const userRoutes: RouteConfig[] = [
  {
    name: 'Plugin.Classin',
    path: 'plugin/class-in/open-classes',
    getTitle: () => $t('ClassIn 开放课'),
    module: [SchoolPluginsEnum.OpenCourse],
    component: React.lazy(() =>
      import('./pages/user/OpenClassList').then(m => ({
        default: m.OpenClassList,
      })),
    ),
  },
  {
    path: 'plugin/class-in/open-classes/:classId',
    name: 'Plugin.OpenClassDetails',
    getTitle: () => $t('开放课详情'),
    component: React.lazy(() =>
      import('./pages/user/OpenClassDetails').then(m => ({
        default: m.OpenClassDetails,
      })),
    ),
  },
  {
    name: 'Plugin.ClassInAuth',
    path: 'plugin/class-in/auth',
    getTitle: () => $t('ClassIn 授权'),
    module: [PluginEnum.ClassIn],
    showSidebarMenu: false,
    component: React.lazy(() =>
      import('./pages/user/Auth').then(m => ({
        default: m.ClassInAuth,
      })),
    ),
  },
]

export const adminRoutes: RouteConfig[] = [
  {
    name: 'Plugin.AdminClassIn',
    path: 'admin/plugin/class-in',
    getTitle: () => $t('ClassIn 管理'),
    module: [PluginCategoryEnum.教务教学, PluginEnum.ClassIn],
    component: React.lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        name: 'OnlineClasses',
        path: 'online-classes',
        getTitle: () => $t('本校线上课'),
        component: React.lazy(() =>
          import('./pages/admin/OnlineClassList').then(m => ({
            default: m.AdminOnlineClassList,
          })),
        ),
      },
      {
        name: 'SharedClasses',
        path: 'shared-classes',
        getTitle: () => $t('跨校共享课'),
        component: React.lazy(() =>
          import('./pages/admin/SharedClassList').then(m => ({
            default: m.AdminSharedClassList,
          })),
        ),
        subRoutes: [
          {
            path: 'list',
            getTitle: () => $t('全部共享课'),
            component: React.lazy(() =>
              import('./pages/admin/SharedClassList/List').then(m => ({
                default: m.AdminSharedClassList,
              })),
            ),
          },
          {
            path: 'my-shared-list',
            getTitle: () => $t('我共享的'),
            component: React.lazy(() =>
              import('./pages/admin/SharedClassList/MySharedList').then(m => ({
                default: m.AdminMySharedClassList,
              })),
            ),
          },
        ],
      },
      {
        name: 'OnlineClassLogs',
        path: 'online-class-logs',
        getTitle: () => $t('操作日志'),
        component: React.lazy(() =>
          import('./pages/admin/OnlineClassLogs').then(m => ({
            default: m.AdminOnlineClassLogs,
          })),
        ),
      },
    ],
  },
  {
    name: 'Plugin.AdminOnlineClassLessonList',
    path: 'admin/plugin/class-in/online-classes/:classId/lessons',
    getTitle: () => $t('课节管理'),
    component: React.lazy(() =>
      import('./pages/admin/OnlineClassLessonList').then(m => ({
        default: m.AdminOnlineClassLessonList,
      })),
    ),
  },
  {
    name: 'Plugin.AdminOnlineClassLessonsBatchEdit',
    path: 'admin/plugin/class-in/online-classes/:id/lessons/batch-edit',
    getTitle: () => $t('批量编辑课节'),
    component: React.lazy(() =>
      import('./pages/admin/BatchEditLessons').then(m => ({
        default: m.AdminBatchEditLessons,
      })),
    ),
  },
  {
    name: 'AdminOnlineClassDetails',
    path: 'admin/plugin/class-in/online-classes/:classId/details',
    getTitle: () => $t('线上课详情'),
    component: React.lazy(() =>
      import('./pages/admin/OnlineClassDetails').then(m => ({
        default: m.AdminOnlineClassDetails,
      })),
    ),
  },
  {
    name: 'AdminOnlineClassShow',
    path: 'admin/plugin/class-in/online-classes/:classId/view',
    getTitle: () => $t('线上课详情'),
    component: React.lazy(() =>
      import('./pages/admin/OnlineClassShow').then(m => ({
        default: m.AdminOnlineClassShow,
      })),
    ),
    subRoutes: [
      {
        path: 'home',
        getTitle: () => $t('主页'),
        component: React.lazy(() =>
          import('./pages/admin/OnlineClassShow/Home').then(m => ({
            default: m.AdminOnlineClassHome,
          })),
        ),
      },
      {
        path: 'tasks',
        getTitle: () => $t('任务'),
        component: React.lazy(() =>
          import('./pages/admin/OnlineClassShow/Tasks').then(m => ({
            default: m.AdminOnlineClassTasks,
          })),
        ),
      },
      {
        path: 'grades',
        getTitle: () => $t('成绩'),
        component: React.lazy(() =>
          import('./pages/admin/OnlineClassShow/Grades').then(m => ({
            default: m.AdminOnlineClassGrades,
          })),
        ),
      },
    ],
  },
  {
    name: 'AdminSharedClassNew',
    path: 'admin/plugin/class-in/shared-classes/:classId/new',
    getTitle: () => $t('加入课程班'),
    component: React.lazy(() =>
      import('./pages/admin/SharedClassNew').then(m => ({
        default: m.AdminSharedClassNew,
      })),
    ),
  },
  {
    name: 'AdminSharedClassDetails',
    path: 'admin/plugin/class-in/shared-classes/:classId/details',
    getTitle: () => $t('共享课详情'),
    component: React.lazy(() =>
      import('./pages/admin/SharedClassDetails').then(m => ({
        default: m.AdminSharedClassDetails,
      })),
    ),
  },
  {
    name: 'Plugin.AdminEditSyncOnlineClassSetting',
    path: 'admin/plugin/class-in/online-classes/edit-sync-setting/:id',
    getTitle: () => $t('编辑规则'),
    component: React.lazy(() =>
      import('./pages/admin/EditSyncOnlineClassSetting/Edit').then(m => ({
        default: m.EditSyncOnlineClassSetting,
      })),
    ),
  },
  {
    name: 'Plugin.AdminNewSyncOnlineClassSetting',
    path: 'admin/plugin/class-in/online-classes/edit-sync-setting',
    getTitle: () => $t('新增课程同步'),
    component: React.lazy(() =>
      import('./pages/admin/EditSyncOnlineClassSetting/New').then(m => ({
        default: m.NewSyncOnlineClassSetting,
      })),
    ),
  },
  {
    name: 'Plugin.AdminSyncOnlineClassSettings',
    path: 'admin/plugin/class-in/online-classes/sync-settings',
    getTitle: () => $t('课程同步管理'),
    component: React.lazy(() =>
      import('./pages/admin/SyncOnlineClassSettings').then(m => ({
        default: m.SyncOnlineClassSettings,
      })),
    ),
  },
  {
    name: 'Plugin.AdminClassInSettings',
    path: 'admin/plugin/class-in/settings',
    getTitle: () => $t('设置'),
    component: React.lazy(() =>
      import('./pages/admin/Settings').then(m => ({
        default: m.Settings,
      })),
    ),
  },
  {
    name: 'Plugin.AdminClassInSettings.SyncSchoolStructures',
    path: 'admin/plugin/class-in/settings/sync-school-structures',
    getTitle: () => $t('同步结构管理'),
    component: React.lazy(() =>
      import('./pages/admin/SyncSchoolStructures').then(m => ({
        default: m.SyncSchoolStructures,
      })),
    ),
  },
]

export const routes = [...userRoutes, ...adminRoutes]

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'OnlineClassTaskDetailsModal',
    getTitle: () => $t('线上课任务详情'),
    component: React.lazy(() =>
      import('./pages/admin/OnlineClassShow/TaskDetails').then(m => ({
        default: m.OnlineClassTaskDetailsModal,
      })),
    ),
  },
]
