import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { adminRoutes, modalRoutes } from './routes'
import { examFrontendSubRoutes } from './slots'
import { todos } from './todos'

export const initOnlineScoring: InitPluginFeature = {
  name: PluginNameEnum.OnlineScoring,
  init: () => ({
    slots: {
      examScoreDropdownItems: () =>
        import('./slots').then(m => m.examScoreDropdownItems),
      examFrontendSubRoutes,
      examSubjectAnalysisSidebarTabItems: () =>
        import('./slots').then(m => m.examSubjectAnalysisSidebarTabItems),
      teacherExamListColumns: () =>
        import('./slots').then(m => m.teacherExamListColumns),
      adminClassExamGradeListColumns: () =>
        import('./slots').then(m => m.adminClassExamGradeListColumns),
    },
    routes: adminRoutes,
    modalRoutes,
    todos,
  }),
}
