import React from 'react'

import { ModuleEnum, PluginEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

/**
 * 考试前台 routes slot
 */
export const examFrontendRoutes: RouteConfig[] = [
  {
    path: 'teacher/exams/exam-list/frontend/mentor/:examId/analysis',
    getTitle: () => $t('考试分析'),
    name: 'PluginCustomizedTeacherFrontendMentorExam',
    component: React.lazy(() =>
      import('../pages/user/ExamAnalysis').then(m => ({
        default: m.ExamAnalysisWithTabs,
      })),
    ),
    requireModules: [ModuleEnum.Exam, PluginEnum.ExamsFrontend],
    subRoutes: [
      {
        path: 'total-score',
        getTitle: () => $t('成绩总表'),
        component: React.lazy(() =>
          import('@/features/exams/components/Analysis/TotalScore').then(m => ({
            default: m.ExamTotalScoreAnalysis,
          })),
        ),
      },
      {
        path: 'mentor-analysis',
        name: 'MentorAnalysis',
        getTitle: () => $t('导师分析'),
        component: React.lazy(() =>
          import('../pages/user/ExamAnalysis/MentorAnalysis').then(m => ({
            default: m.MentorAnalysis,
          })),
        ),
      },
    ],
  },
]
