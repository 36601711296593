import { isAxiosError } from '@seiue/axios'
import { reportToEngineer } from '@seiue/sentry'
import { compact } from '@seiue/util'
import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

import { hasEnabledPluginAtom } from 'packages/feature-utils/plugins'
import { selectedSemesterAtom } from 'packages/feature-utils/semesters'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { MenuType } from 'packages/route'
import { RoleEnum } from 'packages/sdks-next/chalk'
import { examScoringApi$queryPaperManagedExamSubjects } from 'packages/sdks-next/vnas'
import { MenuProvider } from 'packages/web-layout/menu-utils'
import { MenuItemUnion } from 'packages/web-layout/types'

import { getStoreForAtom } from '@/store'

export const examsFrontendMenu: MenuProvider = loadable(
  atom(async get => {
    const enabled = get(hasEnabledPluginAtom(PluginNameEnum.ExamsFrontend))

    const store = getStoreForAtom(get)
    const me = store.session.currentReflection
    const isStudent = me?.role === RoleEnum.Student
    const isTeacher = me?.role === RoleEnum.Teacher

    // 是否安装启用了阅卷插件
    const onlineScoringEnabled = get(
      hasEnabledPluginAtom(PluginNameEnum.OnlineScoring),
    )

    // 是否安装启用了第三方阅卷
    const thirdPartyScoringEnabled = get(
      hasEnabledPluginAtom(PluginNameEnum.ThirdPartyScoring),
    )

    // 是否能访问出卷列表
    let paperManagedExamSubjectsEnabled = false

    const selectedSemester = get(selectedSemesterAtom)

    if (
      enabled &&
      (onlineScoringEnabled || thirdPartyScoringEnabled) &&
      selectedSemester &&
      !isStudent
    ) {
      try {
        const { data: paperManagedExamSubjects } =
          await examScoringApi$queryPaperManagedExamSubjects.api(
            selectedSemester.id,
            {
              paginated: 1,
              perPage: 1,
            },
          )

        paperManagedExamSubjectsEnabled = Boolean(
          paperManagedExamSubjects?.length,
        )
      } catch (e) {
        // 如果用户没有出卷信息，那么会返回 403，这是约定好的，不需要上报
        if (!isAxiosError(e, 403)) {
          reportToEngineer(e)
        }
      }
    }

    if (!enabled) return []

    return [
      {
        name: PluginNameEnum.ExamsFrontend,
        label: $t('考试'),
        type: MenuType.Apps,
        subMenus:
          !isTeacher && !isStudent
            ? []
            : compact([
                isStudent
                  ? {
                      label: $t('考试列表'),
                      path: '/student/exams/exam-list',
                      icon: 'Examination',
                    }
                  : {
                      label: $t('考试分析'),
                      path: '/teacher/exams/exam-list',
                      icon: 'Examination',
                    },
                paperManagedExamSubjectsEnabled && !isStudent
                  ? {
                      label: $t('出卷列表'),
                      path: '/teacher/exams/question-list',
                      icon: 'Examination',
                    }
                  : null,
                onlineScoringEnabled && !isStudent
                  ? {
                      label: $t('阅卷列表'),
                      path: '/teacher/exams/scoring-list',
                      icon: 'Examination',
                    }
                  : null,
              ]),
      },
    ] as MenuItemUnion[]
  }),
)
