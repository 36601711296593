/**
 * @file routes
 */

import { lazy } from 'react'

import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

export const routes: RouteConfig[] = [
  {
    path: 'admin/plugin/cgr/reports',
    getTitle: () => $t('报告单'),
    name: 'AdminCgrReports',
    module: [PluginCategoryEnum.其他, SchoolPluginsEnum.Cgr],
    component: lazy(() =>
      import('./pages').then(mod => ({
        default: mod.CgrMain,
      })),
    ),
    subRoutes: [
      {
        path: 'normal',
        getTitle: () => $t('在校学生'),
        component: lazy(() =>
          import('./pages/reports/List').then(m => ({
            default: m.GradesReportsList,
          })),
        ),
      },

      {
        path: 'archived',
        getTitle: () => $t('已归档学生'),
        component: lazy(() =>
          import('./pages/reports/List').then(m => ({
            default: m.GradesReportsList,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/plugin/cgr/status',
    getTitle: () => $t('成绩状态'),
    module: [PluginCategoryEnum.其他, SchoolPluginsEnum.Cgr],
    component: lazy(() =>
      import('./pages/status').then(m => ({
        default: m.StatuReportsPlugin,
      })),
    ),
  },

  {
    path: 'admin/plugin/cgr/settings',
    getTitle: () => $t('设置'),
    module: [PluginCategoryEnum.其他, SchoolPluginsEnum.Cgr],
    component: lazy(() =>
      import('./pages/settings').then(m => ({
        default: m.SettingsGradesReports,
      })),
    ),
  },

  {
    path: 'admin/plugin/cgr/settings/roles/new',
    getTitle: () => $t('编辑成绩分析角色'),
    component: lazy(() =>
      import('./pages/settings/components/roles/components/roleEdit').then(
        m => ({
          default: m.RoleEditWrapper,
        }),
      ),
    ),
    // requirePermissions: [PermissionNameEnum.CoreGradeReportWrite],
  },

  {
    path: 'admin/plugin/cgr/settings/roles/:roleId/edit',
    getTitle: () => $t('编辑成绩分析角色'),
    component: lazy(() =>
      import('./pages/settings/components/roles/components/roleEdit').then(
        m => ({
          default: m.RoleEditWrapper,
        }),
      ),
    ),
    // requirePermissions: [PermissionNameEnum.CoreGradeReportWrite],
  },

  {
    path: 'admin/plugin/cgr/student/report/:sid',
    name: 'AdminCgrReports.StudentReport',
    getTitle: () => $t('学生报告单'),
    component: lazy(() =>
      import('./pages/reports/student').then(m => ({
        default: m.StudentReport,
      })),
    ),
    subRoutes: [
      {
        path: 'semester',
        getTitle: () => $t('学期报告单'),
        component: lazy(() =>
          import(
            './pages/reports/student/StudentSemesterReport/SzwStudentSemesterReport'
          ).then(m => ({
            default: m.SzwStudentSemesterReport,
          })),
        ),
      },

      {
        path: 'semester-szcz',
        getTitle: () => $t('学期报告单'),
        component: lazy(() =>
          import(
            './pages/reports/student/StudentSemesterReport/SzCzStudentSemesterReport'
          ).then(m => ({
            default: m.SzCzStudentSemesterReport,
          })),
        ),
      },

      {
        path: 'normal',
        getTitle: () => $t('分项报告单'),
        component: lazy(() =>
          import(
            './pages/reports/student/StudentGradeReport/SzwStudentGradeReport'
          ).then(m => ({
            default: m.SzwStudentGradeReport,
          })),
        ),
      },
      {
        path: 'normal-xhpm',
        getTitle: () => $t('成绩报告单'),
        component: lazy(() =>
          import(
            './pages/reports/student/StudentGradeReport/XHPMStudentGradeReport'
          ).then(m => ({
            default: m.XHPMStudentGradeReport,
          })),
        ),
      },
      {
        path: 'normal-szcz',
        getTitle: () => $t('考试成绩单'),
        component: lazy(() =>
          import(
            './pages/reports/student/StudentGradeReport/SzCzStudentGradeReport'
          ).then(m => ({
            default: m.SzCzStudentGradeReport,
          })),
        ),
      },

      {
        path: 'go-abroad-szcz',
        getTitle: () => $t('出国成绩单'),
        component: lazy(() =>
          import('./pages/reports/student/SzczGoAbroad').then(m => ({
            default: m.SzczGoAbroad,
          })),
        ),
      },

      {
        path: 'resit-szcz',
        getTitle: () => $t('补考/重考成绩单'),
        component: lazy(() =>
          import('./pages/reports/student/SzczResitReport').then(m => ({
            default: m.SzczResitReport,
          })),
        ),
      },
    ],
  },
]
